<template>
  <div class="experience-card-content mb-10">
    <el-row type="flex" justify="space-between" style="height: 100%;">
      <el-col :span="12" :xs="24">
        <h3 class="mt-10 mb-0" style="font-weight: normal;">{{ name }}</h3>
        <span class="hidden-xs-only">{{ experience.start | epochAsDate }} - {{ experience.end | epochAsDate }}</span>
        <br class="hidden-xs-only"/>
        <span class="hidden-xs-only">@{{ experience.handle }}</span>
      </el-col>
      <el-col :span="12" class="text--right hidden-xs-only">
        <div style="display: flex; justify-content: flex-end; height: 100%;">
          <div class="tweets-count hidden-xs-only">
            <span
              class="tweets-count__count"
              style="border-bottom-color: rgb(119, 148, 120);"
            >{{ metrics.approved || '0' }}</span>
            <span>Approved</span>
          </div>
          <div class="tweets-count hidden-xs-only">
            <span
              class="tweets-count__count"
              style="border-bottom-color: white;"
            >{{ !metrics.pending ? '0' : metrics.pending >= 100 ? '>= 100' : metrics.pending }}</span>
            <span>Pending</span>
          </div>
          <div class="tweets-count hidden-xs-only">
            <span
              class="tweets-count__count"
              style="border-bottom-color: rgb(229, 60, 99);"
            >{{ metrics.declined || '0' }}</span>
            <span>Rejected</span>
          </div>
          <div class="tweets-count hidden-xs-only">
            <span
              class="tweets-count__count"
              style="border-bottom-color: rgb(138, 98, 63);"
            >{{ total || '0' }}</span>
            <span>Total</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { pipe, mergeDeepRight, pathOr } from 'ramda'
import { mapActions } from 'vuex'

// takes the 'metrics' object from an experience's widgetData
const getMetricsFromExperience = pipe(
  pathOr({}, ['moderation', 'metrics']),
  mergeDeepRight({
    pending: 0,
    approved: 0,
    declined: 0
  })
)

export default {
  name: 'ModerateExperienceInfo',

  data () {
    return {
      metrics: getMetricsFromExperience(this.experience),
      metricsRefreshInterval: null
    }
  },

  props: {
    experience: { type: Object, required: true }
  },

  computed: {
    name () {
      if (this.experience && this.experience.name && this.experience.name.length > 0) {
        return this.experience.name.toUpperCase()
      }

      return ''
    },

    total () {
      return this.metrics.pending + this.metrics.declined + this.metrics.approved
    }
  },

  async beforeDestroy () {
    await this.unloader()
  },

  mounted () {
    this.setMetricsRefreshInterval()

    /* istanbul ignore next */
    window.addEventListener('beforeunload', () => {
      this.unloader()
    })
  },

  methods: {
    ...mapActions(['validateToken']),

    unloader () {
      this.clearMetricsRefreshInterval()
    },

    async getMetrics () {
      const url = `${process.env.VUE_APP_API_URL}/experiences/${this.$route.params.id}`
      const response = await this.$ky.get(url)

      if (!response) {
        this.$notify({
          type: 'error',
          title: 'A network error occured',
          message: 'Please refresh your page.',
          duration: 0
        })
        return
      }

      if (response.status === 200) {
        const experience = response.data.data

        this.metrics = getMetricsFromExperience(experience)
      }

      if (response.status === 403) {
        this.$notify({
          type: 'error',
          title: 'Not Authorized',
          message: 'You are not authorized to access this page.'
        })
        this.validateToken()

        this.metrics = getMetricsFromExperience()
      }
    },

    setMetricsRefreshInterval () {
      this.getMetrics()

      this.metricsRefreshInterval = window.setInterval(() => {
        this.getMetrics()
      }, process.env.VUE_APP_MODERATION_METRIC_REFRESH_INTERVAL || 60000)
    },

    clearMetricsRefreshInterval () {
      window.clearInterval(this.metricsRefreshInterval)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colours.scss';

.experiences-card a {
  text-decoration: none;
}

.experience-img {
  border-radius: 30px;
}

.experience-card-content {
  padding: 20px;

  @media (max-width: 768px) {
    padding: 5px;
  }
}

.experience-type-handle {
  font-family: AvenirNext-DemiBold, serif;
  font-size: 10px;
  color: #4ab3f4;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.tweets-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;

  & .tweets-count__count {
    width: fit-content;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-bottom: 10px;
    padding-bottom: 3px;
    font-weight: bold;
  }
}
</style>
